

import React from 'react'
import { FaWhatsapp,FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";


const Social = () => {
  return (
    <div className='d-flex gap-2'> <span><a href="https://www.facebook.com/Primehrms-293909637674186"><FaFacebookF /></a></span>
    <span><a href="https://instagram.com/primehrms?igshid=NzNkNDdiOGI="><FaInstagram /></a></span>
    <span><a href="https://wa.me/923226633108"><FaWhatsapp /></a></span>
    <span><a href="https://www.linkedin.com/company/prime-hrms/"><FaLinkedinIn /></a></span></div>
  )
}

export default Social