import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from 'reactstrap'
import digital from '../../../assets/images/logo.png'
import Social from "../Social"
import './Header.css'
import Slide from 'react-reveal/Slide';
import { useRef, useEffect } from 'react'
import { RiMenu5Fill } from 'react-icons/ri'
import { MdOutlineClose } from 'react-icons/md'


function Navigation() {

  const headerRef = useRef(null)
  const menuRef = useRef(null)


  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        headerRef.current.classList.add('header_shrink')
      } else {
        headerRef.current.classList.remove('header_shrink')
      }
    })

    return () => {
      window.removeEventListener('scroll')
    }

  }, [])

  const menuToggle = () => menuRef.current.classList.toggle('menu_active')
  const closeToggle = () => menuRef.current.classList.remove('menu_active')

  return (

    <div className="header">


      <div className="main-header w-100">
        <Container className="relative-header-container">

          <div className="top-Header d-flex justify-content-between py-2">
            <div className="contact-top-header">
              {/* <p className="mb-0">Contact us now</p> */}
            </div>
            <div className="social-header d-flex align-items-center gap-4">
              <div><p className="mb-0 ">Follow us on </p> </div>
              <Social />
            </div>

          </div>
          <div className="sticky-heaher" ref={headerRef}>
            <div className="container">


              <nav class="navbar navbar-expand-lg bg-light">
                <div class="main-navigation  d-flex align-items-center justify-content-between w-100 p-2">
                  <Slide bottom>
                    <div className="main-logo">

                      <a class="navbar-brand" href="/"><img className="w-50" src={digital} alt="" /></a>
                    </div>
                  </Slide>

                  <div class="navigation-list" ref={menuRef}>
                    <div className="toggle-close" onClick={closeToggle}>
                      <MdOutlineClose />
                    </div>
                    <ul class="navbar-nav ">
                      <li class="nav-item">
                        <NavLink className="nav-link" to="/">
                          Home
                        </NavLink>
                      </li>

                      {/* <li class="nav-item dropdown dropdown-hover position-static">
                        <NavLink className="nav-link" to="/features">
                          Features
                        </NavLink>

               
                      </li> */}


                      <li class="nav-item dropdown dropdown-hover position-static">
                        <NavLink className="nav-link" to="/modules">
                          Modules
                        </NavLink>

                        {/* <div class="dropdown-menu w-100 mt-0" aria-labelledby="navbarDropdown">

                          <div class="container">
                            <div class="row my-4">
                              <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div class="list-group list-group-flush">
                                  <p class="list-group-item list-group-item-action">Lorem ipsum</p>
                                  <p class="list-group-item list-group-item-action">Dolor sit</p>
                                  <p class="list-group-item list-group-item-action">Amet consectetur</p>
                                  <p class="list-group-item list-group-item-action">Cras justo odio</p>
                                  <p class="list-group-item list-group-item-action">Adipisicing elit</p>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-3 mb-3 mb-lg-0">
                                <div class="list-group list-group-flush">
                                  <p class="list-group-item list-group-item-action">Explicabo voluptas</p>
                                  <p class="list-group-item list-group-item-action">Perspiciatis quo</p>
                                  <p class="list-group-item list-group-item-action">Cras justo odio</p>
                                  <p class="list-group-item list-group-item-action">Laudantium maiores</p>
                                  <p class="list-group-item list-group-item-action">Provident dolor</p>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-3 mb-3 mb-md-0">
                                <div class="list-group list-group-flush">
                                  <p class="list-group-item list-group-item-action">Iste quaerato</p>
                                  <p class="list-group-item list-group-item-action">Cras justo odio</p>
                                  <p class="list-group-item list-group-item-action">Est iure</p>
                                  <p class="list-group-item list-group-item-action">Praesentium</p>
                                  <p class="list-group-item list-group-item-action">Laboriosam</p>
                                </div>
                              </div>
                              <div class="col-md-6 col-lg-3">
                                <div class="list-group list-group-flush">
                                  <p class="list-group-item list-group-item-action">Cras justo odio</p>
                                  <p class="list-group-item list-group-item-action">Saepe</p>
                                  <p class="list-group-item list-group-item-action">Vel alias</p>
                                  <p class="list-group-item list-group-item-action">Sunt doloribus</p>
                                  <p class="list-group-item list-group-item-action">Cum dolores</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </li>






                      <li class="nav-item dropdown">
                        <NavLink className="nav-link" to="/pricing">
                          Pricing
                        </NavLink>

                      </li>
                      <li class="nav-item dropdown">
                        <NavLink className="nav-link" to="/contact-us">
                          Contact Us
                        </NavLink>

                      </li>
                      <li class="nav-item dropdown">
                        <NavLink className="nav-link" to="/privacy-policy">
                          Privacy Policy
                        </NavLink>

                      </li>

                      <li class="nav-item dropdown">
                        <a className="nav-link" href="https://primehrms.com/loginui.aspx">
                          Sign in
                        </a>

                      </li>
                      <li className="nav-item dwonload-btn-list">
                      <a href="https://web.primehrms.com/contact-us"><button type="button" className="btn">Register Now</button></a>
                      </li>

                    </ul>

                  </div>
                  <div className="mobile-navigation">
                    <div className="toggle-menu" onClick={menuToggle}>
                      <RiMenu5Fill />
                    </div>


                  </div>
                </div>
              </nav>
            </div>
          </div>


        </Container>
      </div>


    </div>
  );
}

export default Navigation;