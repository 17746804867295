

import React from 'react'
import './Modules.css'
import Sixth from '../../../assets/images/sixth-img.png'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'
import task1 from '../../../assets/images/task-1.png'
import task2 from '../../../assets/images/task-2.png'
import { MdKeyboardArrowRight } from "react-icons/md"
import prime4 from '../../../assets/images/prime-4.png'
import prime6 from '../../../assets/images/prime-6.png'
import Attendance1 from '../../../assets/images/attendance-1.png'
import Attendance2 from '../../../assets/images/attendance-2.png'
import employe1 from '../../../assets/images/employe-1.png'
import employe2 from '../../../assets/images/employe-2.png'
import payroll1 from '../../../assets/images/payroll-1.png'
import payroll2 from '../../../assets/images/payroll-2.png'
import ess from '../../../assets/images/ess.png'
import loan from '../../../assets/images/loanadvance.png'
import performance from '../../../assets/images/performance-mgmt.png'
import reports from '../../../assets/images/reports-analysis.png'
import atsOne from '../../../assets/images/ats-mg2.png'
import atsTwo from '../../../assets/images/ats-mg.png'
import onbaord from '../../../assets/images/onboard.png'

import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap"
import Fade from 'react-reveal/Fade';

const Modules = () => {
    useEffect(() => {
        document.body.classList.add("module-page");
        return () => {
            document.body.classList.remove("module-page");
        };
    }, []);

    var sectionStyle = {
        width: "100%",
        backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",


    };


    return (
        <div className="main-content">
            <div className="feature-gradient">
                <div className="feature-bg" style={sectionStyle}>
                    <div className="container">
                        <div className="row feature-bg-row">
                            <Fade left>


                                <div className="col-lg-6 feature-banner-col">
                                    <div className="feature-banner-content">
                                        <h2>
                                            Module of PRIME HRMS

                                        </h2>
                                        <p className="banner-desc">
                                            Create stunning features with unlimited style possibilities.
                                        </p>
                                        <a href="https://primehrms.com/PrimeRegistration.aspx">
                                <button className="btn btn-light">
                                    Schedule a Demo
                                </button>
                                </a>
                                    </div>
                                </div>

                            </Fade>

                            <Fade right>
                                <div className="col-lg-6">
                                    <div className="feature-banner-imgs">
                                        <div className="banner-img">
                                            <img src={Sixth} alt="" />
                                        </div>



                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Time & Attendance
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS is sophisticated platform for attendance management. Not only is it responsible for maintaining visibility on employee movements.                                    </p>
                                 
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={Attendance2} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={Attendance1} alt="" />
                                </div>


                            </div>


                        </Col>



                    </Row>

                    <Row className='second-row-module'>
                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-three">
                                    <img src={prime4} alt="" />
                                </div>
                                <div className="module-image-four">
                                    <img src={prime6} alt="" />
                                </div>

                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll">
                                <h2 className="main-heading mb-3">
                                    Leave Management
                                </h2>
                                <p className="module-desc">

                                    It is used by every organizations to simplify, drop the administrative hurdles, monitor, plan, and track leaves of absences. It allows staff to request any leave of absence.                                    </p>
                                 
                            </div>
                        </Col>





                    </Row>
                </section>
                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Employee Management
                                </h2>
                                <p className="module-desc">

                                    We provide a platform where all work-related as well as important personal details of an employee is stored and managed in a secure way.                                     
                                </p>
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={employe1} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={employe2} alt="" />
                                </div>


                            </div>


                        </Col>



                    </Row>

                    <Row className='second-row-module'>
                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-three">
                                    <img src={task1} alt="" />
                                </div>
                                <div className="module-image-four">
                                    <img src={task2} alt="" />
                                </div>

                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll">
                                <h2 className="main-heading mb-3">
                                    Task Management
                                </h2>
                                <p className="module-desc">

                                    Task management module of Prime HRMS deals with various aspects of a project such as dividing the whole project into smaller tasks and subtasks.
                                </p>
                                 
                            </div>
                        </Col>





                    </Row>
                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Payroll Management
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS provide a tool that enables your business to handle all your employee's financial records in a hassle-free, automated fashion.                                     </p>
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={payroll1} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={payroll2} alt="" />
                                </div>


                            </div>


                        </Col>



                    </Row>


                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={performance} alt="" />
                                </div>



                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll">
                                <h2 className="main-heading mb-3">
                                    Performance Management
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS provide a approach to measure the performance of employees. Through this your organization can align their objectives with resources systems.
                                </p>
                                 
                            </div>
                        </Col>





                    </Row>


                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Applicant Tracking
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS provide an applicant tracking system (ATS) it enables the electronic handling of recruitment and hiring needs.
                                     </p>
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={atsOne} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={atsTwo} alt="" />
                                </div>


                            </div>


                        </Col>



                    </Row>


                </section>



                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={prime4} alt="" />
                                </div>



                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Over Time
                                </h2>
                                <p className="module-desc">

                                    It helps you to define and configure overtime settings like minimum & maximum overtime hours, calculation factor for weekly.
                                     </p>
                            </div>
                        </Col>





                    </Row>


                </section>


                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Employee Self Service
                                </h2>
                                <p className="module-desc">

                                    It provides management support system that facilitates and supports employees on daily basis.
                                     </p>
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={ess} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={prime4} alt="" />
                                </div>


                            </div>


                        </Col>



                    </Row>


                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={loan} alt="" />
                                </div>



                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Loans & Advances
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS help automate the entire loan lifecycle. Depending on requirements. Prime HRMS can help with processing customer information more.
                                     </p>
                            </div>
                        </Col>





                    </Row>


                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    Reports & Analysis
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS provide a approach to measure the performance of employees. Through this your organization can align their objectives with resources systems
                                     </p>
                            </div>
                        </Col>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={reports} alt="" />
                                </div>
                                <div className="module-image-two">
                                    <img src={employe1} alt="" />
                                </div>

                            </div>


                        </Col>



                    </Row>


                </section>

                <section id="module-section">
                    <Row>

                        <Col lg='6'>
                            <div className="module-image-wrapper">
                                <div className="module-image-one">
                                    <img src={onbaord} alt="" />
                                </div>



                            </div>


                        </Col>

                        <Col lg='6'>
                            <div className="module-data-wrapper sticky-scroll mr-10">
                                <h2 className="main-heading mb-3">
                                    On / off boarding
                                </h2>
                                <p className="module-desc">

                                    Prime HRMS give simple and efficient on boarding system as HR Managers face numerous challenges on a day-to-day basis.
                                     </p>
                            </div>
                        </Col>





                    </Row>


                </section>

            </Container>

        </div>
    )
}

export default Modules