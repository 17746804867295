

import React from 'react'
import heroOne from '../../../../assets/images/11.jpeg'
import heroTwo from '../../../../assets/images/12.jpeg'
import heroThree from '../../../../assets/images/13.jpg'
import heroFour from '../../../../assets/images/14.jpg'
import heroFive from '../../../../assets/images/15.jpeg'
import heroSix from '../../../../assets/images/16.jpeg'

import blue from '../../../../assets/images/blue-circle.png'
import pink from '../../../../assets/images/pink-circle.png'
import grey from '../../../../assets/images/grey-circle.png'
import yellow from '../../../../assets/images/yellow-circle.png'
import './Hero.css'
import Slider from 'react-slick'
import Fade from 'react-reveal/Fade';

const Hero = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true

    };

    const HeroBanner = [
        {
            img: heroOne,
            imgTwo: heroTwo,

        },
        {

            img: heroThree,
            imgTwo: heroFour,

        },
        {

            img: heroFive,
            imgTwo: heroSix,

        },
    ]

    return (
        <section className="hero-banner">


            <div className="bubble-images">
                <div class="image-one hero-moveable-images">
                    <img src={blue} alt="" className="moveable-css" />
                </div>
                <div class="image-two">
                    <img src={pink} alt="" className="moveable-css" />
                </div>
                <div class="image-three">
                    <img src={grey} alt="" className="moveable-css" />
                </div>
                <div class="image-four">
                    <img src={yellow} alt="" className="moveable-css" />
                </div>
            </div>



            <div className="container">
                <div className="row  py-5 hero-banner-row">
                    <div className="col-lg-5 hero-content-col d-flex justify-content-center flex-lg-column gap-5">

                        <div className='hero-content'>

                            <Fade bottom cascade>
                                <div className="hero-heading">

                                    <h2 className='main-heading'>

                                        Spending too much time on HR, not your business?<br></br>
                                        We can fix that.

                                    </h2>

                                </div>
                            </Fade>



                            <div className="hero-desc">
                                <p>
                                    Centralize And Automate All Your HR Information. Payroll, Employee management, ESS and much more in Prime HRMS
                                </p>
                            </div>



                            <div className="hero-buttons">
                                <a href="https://primehrms.com/PrimeRegistration.aspx">
                                <button className="btn first">
                                    Schedule a Demo
                                </button>
                                </a>
                                <a href="https://web.primehrms.com/contact-us">
                                <button className="btn second">
                                    Contact Us
                                </button>
                                </a>
                            </div>

                        </div>

                    </div>
                    <div className="col-lg-7">
                        <div className="row banner-slider-img">

                            <Slider {...settings}>




                                {
                                    HeroBanner.map((item, index) => (

                                        <div>
                                            <div className="single_banner" key={index}>

                                                <div className='hero-top-content d-flex align-content-center gap-4'>
                                                    <div className="first-hero">
                                                        <img src={item.img} className='w-100 ' />
                                                    </div>
                                                    <div className="second-hero">
                                                        <img src={item.imgTwo} className='w-100 ' />
                                                    </div>




                                                </div>


                                            </div>
                                        </div>

                                    ))
                                }

                            </Slider>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero