import React from "react";
import Hero from "./Hero/Hero";
import Second from "./Second/Second";
import Third from "./Third/Third";
import Fourth from "./Fourth/Fourth";
import Fifth from "./Fifth/Fifth";
import Sixth from "./Sixth/Sixth";
import Pricing from "./Pricing/Pricing";
import Features from "./Features/Features";
import { useEffect } from "react";

function Home() {

  useEffect(() => {
    document.body.classList.add("home-page");
    return () => {
      document.body.classList.remove("home-page");
    };
  }, []);

  return (
    <>
   

  
      <Hero />
      <Fifth />
      <Second />
      <Fourth />
      <Features />
      <Pricing />
      <Sixth />
      <Third />
     
      
      
     
     
    </>

  );
}

export default Home;