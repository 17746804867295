import React from "react";
import './Contact.css'
import Sixth from '../../../assets/images/sixth-img.png'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'
import formbg from '../../../assets/images/contact-simple-image.jpg'
import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap"
import { BsTelephoneFill } from "react-icons/bs"
import { IoMdMail } from "react-icons/io"
import { BiWorld } from "react-icons/bi"
import Form from '../Form/Form'
import Fade from 'react-reveal/Fade';

import './Contact.css'

function Contact() {

  const ContactItem = [
    {
      imgIcon: <BsTelephoneFill />,
      title: "Phone",
      desc: "+92 322 6633108",
    },
    {
      imgIcon: <IoMdMail />,
      title: "Send an Email",
      desc: "info@primehrms.com",

    },
    {
      imgIcon: <BiWorld />,
      title: "Visit Our Office",
      desc: "Mall of Faisalabad, Fsd",
    }

  ]

  useEffect(() => {
    document.body.classList.add("contact-page");
    return () => {
      document.body.classList.remove("contact-page");
    };
  }, []);

  var sectionStyle = {
    width: "100%",
    backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",


  };

  return (
    <div className="main-content">

      <div className="feature-gradient">
        <div className="feature-bg" style={sectionStyle}>
          <div className="container">
            <div className="row feature-bg-row">
              <Fade left>
                <div className="col-lg-6 feature-banner-col">
                  <div className="feature-banner-content">
                    <h2>
                    Get in Touch

                    </h2>
                    <p className="banner-desc">
                    We want to hear from you. Let us know how we can help.

                    </p>
                    <a href="https://primehrms.com/PrimeRegistration.aspx">
                    <button className="btn btn-light">Schedule a Demo</button>
                    </a>
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className="col-lg-6">
                  <div className="feature-banner-imgs">
                    <div className="banner-img">
                      <img src={Sixth} alt="" className="hero-moveable-images" />
                    </div>
                  



                  </div>

                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section id="footer-info-section">
        <Container>
          <Row>
            {ContactItem.map((item, index) => (

              <Col lg='4'>
                <div className="features-content ContactPage-info">
                  <div className='features-icon'>{item.imgIcon}</div>
                  <div className="features-data-wrap">
                    <div className="features-title"><h2 className='service-title-h2'>{item.title}</h2></div>
                    <div className="features-desc">{item.desc}</div>
                  </div>

                </div>
              </Col>


            )
            )}
          </Row>
        </Container>
      </section>

      <section id="contact-form">
        <Container>
          <Row>

            <Col lg='12'>
              <div className="form-bg-image">
              <h2 class="contact-form-heading">Send us a message</h2>
              <p class="form-subheading">Contact us today using this form and our support team will reach out as soon as possible.</p>
                <iframe  src="https://share.hsforms.com/1Ym9fZixVSheK1dBlWcVQXAdsjuq" title="W3Schools Free Online Web Tutorials">
</iframe>
                  {/* <Form /> */}
               

              </div>

            </Col>



          </Row>
        </Container>
      </section>



    </div>
  );
}

export default Contact;