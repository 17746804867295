


import React from 'react'
import './Second.css'

import { CgNotes } from "react-icons/cg"
import { MdKeyboardArrowRight } from "react-icons/md"
import { GiShakingHands } from "react-icons/gi"
import { MdOutlineAttachMoney } from "react-icons/md"
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import moduleOne from '../../../../assets/images/quality.gif'
import moduleTwo from '../../../../assets/images/flexibility.gif'
import moduleThree from '../../../../assets/images/efficient.gif'
import moduleFour from '../../../../assets/images/access.gif'
import moduleFive from '../../../../assets/images/customize.gif'
import moduleSix from '../../../../assets/images/userfriendly.gif'



const Second = () => {



  const ServicesItems = [
    {
      imgIcon: moduleOne,
      title: "Fast Quality ",
      desc: "Prime HRMS's team delivers successful results with quality work on time. Whatever you require, we deliver with the best quality.",
    },
    {
      imgIcon: moduleTwo,
      title: "Flexibility ",
      desc: "Prime HRMS have ability to change easily in response to different user and system requirements. Flexibility is our key feature.",
      classIcon: "icon-cirle second-icon-circle",
    },
    {
      imgIcon: moduleThree,
      title: "Efficient ",
      desc: "Prime HRMS not only be able to meet the customer requirements but should exceed it. Performance efficiency is maximum.",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: moduleFour,
      title: "Easy Access  ",
      desc: "Prime HRMS is easy to use user friendly interface and different metaphors are used to make things easy for user.",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: moduleFive,
      title: "Customization  ",
      desc: "Prime HRMS can adopt changes & we can customize existing software according to the user’s requirements.pricing-card-highlighted.",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: moduleSix,
      title: "User Friendly",
      desc: "Easy to use things are not difficult to understand. Not overly complex,but instead is straightforward well organized.",
      classIcon: "icon-cirle third-icon-circle",
    }

  ]


  return (
    <section id="second-section" className='help-clients'>
      <div className="container">
        <div className="row">

          <div className="client-goals-heading">

            <div className="client-goals-sub main-subheading">
              <span className="sub-text">
                First time in Pakistan
              </span>
            </div>
            <Fade bottom cascade>
              <h2 className='main-heading'>
                Human Resources<br></br>
                for modern companies
              </h2>
              <p className="clients-goal-desc">
                Prime HRMS can be configured to meet exact needs, from <br></br>small-medium sized businesses to larger enterprise class organizations.

              </p>
            </Fade>
          </div>

        </div>

        <div className="row services-row">


          {ServicesItems.map((item, index) => (
          
            <div className="col-lg-4">
             
                <div className="service-content">
                <Zoom top cascade>
                  <div className="service-title"><h2 className='service-title-h2'>{item.title}</h2></div>
                  <div className="services-img"><img src={item.imgIcon} className="w-100" alt="" /></div>

                  <div className="service-desc">{item.desc}</div>
                  {/* <div className="learn_more-services my-3"><a href='#'>Read more <MdKeyboardArrowRight /></a></div> */}
                  </Zoom>
                </div>
            
            </div>
       

          )
          )}

        </div>

      </div>
    </section>
  )
}

export default Second