

import React from 'react'
import './Fourth.css'
import ozi from '../../../../assets/images/ozi.png'
import tkxel from '../../../../assets/images/tkxel.png'
import alkhidmat from '../../../../assets/images/alkhidmat.jpg'
import arham from '../../../../assets/images/arham.png'
import leverify from '../../../../assets/images/leverify.png'
import FieldForce from '../../../../assets/images/fieldforce.png'
import bringo from '../../../../assets/images/bringo.png'
import gmsa from '../../../../assets/images/gmsa.png'
import Gaminators from '../../../../assets/images/gaminators.png'
import AUJ from '../../../../assets/images/auj.png'
import GSS from '../../../../assets/images/gss.png'
import code from '../../../../assets/images/code.png'
import vertex from '../../../../assets/images/vertex.png'
import amentis from '../../../../assets/images/amentis.png'
import mettlesol from '../../../../assets/images/mettlesol.PNG'
import argon from '../../../../assets/images/argon.png'
import consoli from '../../../../assets/images/consoli.png'
import ascertia from '../../../../assets/images/ascertia.png'
import mizo from '../../../../assets/images/mizo.png'
import zepto from '../../../../assets/images/zepto.png'
import saiban from '../../../../assets/images/saiban.png'
import transdata from '../../../../assets/images/transdata.png'
import allied from '../../../../assets/images/allied.png'
import pny from '../../../../assets/images/pny.png'
import gs from '../../../../assets/images/gs.jpg'
import leads from '../../../../assets/images/leads.png'
import fsp from '../../../../assets/images/fsp.png'
import aito from '../../../../assets/images/aito.png'
import magnus from '../../../../assets/images/magnus.png'
import ait from '../../../../assets/images/ait.png'
import sehar from '../../../../assets/images/sehar.png'
import insight from '../../../../assets/images/insight.png'

import antsoft from '../../../../assets/images/antsoft.png'
import dawood from '../../../../assets/images/dawood.png'
import khayam from '../../../../assets/images/khayam.png'
import jubilee from '../../../../assets/images/jubilee.jpg'
import pws from '../../../../assets/images/pws.png'
import saigol from '../../../../assets/images/saigol.png'

import dialer from '../../../../assets/images/dialer.png'
import senarios from '../../../../assets/images/senarios.png'
import bbg from '../../../../assets/images/bbg.png'
import odyssey from '../../../../assets/images/odyssey.png'
import accountech from '../../../../assets/images/accountech.png'
import websoftic from '../../../../assets/images/websoftic.PNG'

import techswivel from '../../../../assets/images/techswivel.png'
import tradimpex from '../../../../assets/images/tradimpex.png'
import sunrise from '../../../../assets/images/sunrise.png'
import fgs from '../../../../assets/images/fgs.png'
import ocloud from '../../../../assets/images/ocloud.PNG'
import obro from '../../../../assets/images/obro.jpg'

import brilliant from '../../../../assets/images/brilliant.png'

import techmakh from '../../../../assets/images/techmakh.jpg'
import erum from '../../../../assets/images/erum.PNG'
import tiny from '../../../../assets/images/tiny.png'
import cherry from '../../../../assets/images/cherry.png'
import dstech from '../../../../assets/images/dstech.png'


import smile from '../../../../assets/images/smile.png'
import game from '../../../../assets/images/gamespace.jpg'
import inter from '../../../../assets/images/interbolt.PNG'
import manifest from '../../../../assets/images/manifestlogo.jpg'
import avgo from '../../../../assets/images/avogtallogo.png'
import septem from '../../../../assets/images/septemlogo.png'


import Slide from 'react-reveal/Slide';

const Fourth = () => {
  return (
    <section id='fourth'>
    <div className="container">
        <div className="row">
            <div className="col-lg-4">
            <Slide left cascade>
                <div className="clients-wrapper">
                    <div className="client-data-wrap">
                        <p className="clients-no">
                            +150
                        </p>
                        <h2 className="main-heading">
                        Companies trusted and use Prime HRMS around the world
                        </h2>
                        {/* <button className="read_more shadow-btn">Read more stories from clients</button> */}
                    </div>
                </div>
                </Slide>
            </div>
            <div className="col-lg-8 clients-row">
                <Slide right cascade>
                    <div className="clients-wrapper">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="clients-wrapper-box">
                                    <img className='w-100' src={ozi} alt="" />
                                
                                    <p className="client-name">
                                    Ozi Technology
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="clients-wrapper-box">
                                    <img className='w-100' src={tkxel} alt="" />
                                    <p className="client-name">
                                    TKXEL
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="clients-wrapper-box">
                                    <img className='w-100 p25' src={alkhidmat} alt="" />
                                    <p className="client-name">
                                    Alkhidmat foundation
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                            <div className="clients-wrapper-box">
                            <img className='w-100' src={FieldForce} alt="" />
                                    <p className="client-name">
                                        FieldForce
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row  second-clients-row " >
                        
                            <div className="col-lg-3">
                            <div className="clients-wrapper-box">
                            <img className='w-100' src={Gaminators} alt="" />
                                    <p className="client-name">
                                        Gaminators
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                            <div className="clients-wrapper-box">
                                    <img className='w-100' src={argon} alt="" />
                                    <p className="client-name">
                                    argon
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                            <div className="clients-wrapper-box">
                                    <img className='w-100' src={bringo} alt="" />
                                    <p className="client-name">
                                        Bringo
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                            <div className="clients-wrapper-box">
                                    <img className='w-100' src={GSS} alt="" />
                                    <p className="client-name">
                                        GSS
                                    </p>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </Slide>
            </div>
        </div> 
        <div className="row">
            <div className="col-lg-12 clients-row">
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                    <img className='w-100' src={ascertia} alt="" />
                            <p className="client-name">
                            ascertia
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100 p-ait' src={mizo} alt="" />
                            <p className="client-name">
                            mizo
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={zepto} alt="" />
                            <p className="client-name">
                            zepto
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                        <img className='w-100' src={consoli} alt="" />
                            <p className="client-name">
                            consoli
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={saiban} alt="" />
                            <p className="client-name">
                            saiban
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                        <img className='w-100' src={transdata} alt="" />
                            <p className="client-name">
                            transdata
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100 p42' src={AUJ} alt="" />
                            <p className="client-name">
                            AUJ
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100 p42' src={vertex} alt="" />
                            <p className="client-name">
                            vertex
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100 p35' src={gmsa} alt="" />
                            <p className="client-name">
                                GMSA
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100 p35' src={code} alt="" />
                            <p className="client-name">
                            code
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                    <img className='w-100 p37' src={amentis} alt="" />
                            <p className="client-name">
                                Amentis
                            </p>
                        </div>
                    </div>
                    
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box"> 
                            <img className='w-100 p57' src={mettlesol} alt="" />
                            <p className="client-name">
                            mettlesol
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={fsp} alt="" />
                            <p className="client-name">
                            fsp
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={allied} alt="" />
                            <p className="client-name">
                            allied
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={leads} alt="" />
                            <p className="client-name">
                                leads
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={aito} alt="" />
                            <p className="client-name">
                            aito
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                    <img className='w-100' src={pny} alt="" />
                            <p className="client-name">
                                pny
                            </p>
                        </div>
                    </div>
                    
                    <div className="col-lg-2">
                    <div className="clients-wrapper-box">
                            <img className='w-100' src={gs} alt="" />
                            <p className="client-name">
                                gs
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={leverify} alt="" />
                            <p className="client-name">
                            leverify
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box"> 
                            <img className='w-100 p57' src={arham} alt="" />
                            <p className="client-name">
                            arham
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={sehar} alt="" />
                            <p className="client-name">
                            sehar
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={magnus} alt="" />
                            <p className="client-name">
                            magnus
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100 p-ait' src={ait} alt="" />
                            <p className="client-name">
                            ait
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={insight} alt="" />
                            <p className="client-name">
                            insight
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={antsoft} alt="" />
                            <p className="client-name">
                            antsoft
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box"> 
                            <img className='w-100 p57' src={dawood} alt="" />
                            <p className="client-name">
                            dawood
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={khayam} alt="" />
                            <p className="client-name">
                            khayam
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={jubilee} alt="" />
                            <p className="client-name">
                            jubilee
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100 p-ait' src={pws} alt="" />
                            <p className="client-name">
                            pws
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={saigol} alt="" />
                            <p className="client-name">
                            saigol
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={dialer} alt="" />
                            <p className="client-name">
                            dialer 360
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box"> 
                            <img className='w-100 p57' src={senarios} alt="" />
                            <p className="client-name">
                            senarios
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={bbg} alt="" />
                            <p className="client-name">
                            bbg
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={odyssey} alt="" />
                            <p className="client-name">
                            odyssey
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100 p-ait' src={accountech} alt="" />
                            <p className="client-name">
                            accountech
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={websoftic} alt="" />
                            <p className="client-name">
                            websoftic
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={techswivel} alt="" />
                            <p className="client-name">
                            techswivel
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={tradimpex} alt="" />
                            <p className="client-name">
                            tradimpex
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={sunrise} alt="" />
                            <p className="client-name">
                            sunrise
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={fgs} alt="" />
                            <p className="client-name">
                            fgs
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100 p-ait' src={ocloud} alt="" />
                            <p className="client-name">
                            ocloud
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={obro} alt="" />
                            <p className="client-name">
                            obro
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row  second-clients-row " >
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={brilliant} alt="" />
                            <p className="client-name">
                            brilliant
                            </p>
                        </div>
                    </div> 
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={dstech} alt="" />
                            <p className="client-name">
                            dstech
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={tiny} alt="" />
                            <p className="client-name">
                            tiny
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={erum} alt="" />
                            <p className="client-name">
                            erum
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={techmakh} alt="" />
                            <p className="client-name">
                            techmakh
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={cherry} alt="" />
                            <p className="client-name">
                            cherry
                            </p>
                        </div>
                    </div>
                    </div>

                    <div className="row  second-clients-row " >
                    
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={smile} alt="" />
                            <p className="client-name">
                            smile
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={game} alt="" />
                            <p className="client-name">
                            game
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={inter} alt="" />
                            <p className="client-name">
                            inter
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={manifest} alt="" />
                            <p className="client-name">
                            manifest
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={avgo} alt="" />
                            <p className="client-name">
                            avgo
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="clients-wrapper-box">
                            <img className='w-100' src={septem} alt="" />
                            <p className="client-name">
                            septem
                            </p>
                        </div>
                    </div>
                    </div>
            </div>
            </div>
            <div className="row">
            <div className="col-lg-12">
            <div className="hero-buttons b-client" >
                                <a href="https://primehrms.com/PrimeRegistration.aspx">
                                <button className="btn first">
                                    Schedule a Demo
                                </button>
                                </a>
                                <a href="https://web.primehrms.com/contact-us">
                                <button className="btn first">
                                    Contact Us
                                </button>
                                </a>
                            </div>
                            </div>
        </div>
    </div>
    </section>
  )
}

export default Fourth