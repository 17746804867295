import React, { Fragment } from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Feature from './FeaturePage/FeaturePage'
import Modules from './Modules/Modules'
import Contact from './Contact/Contact'
import Pricing from './PricingPage/PricingPage'
import Home from './Home/Home'
import Privacy from './Privacy/Privacy'
import PageNotFound from './PageNotFound/PageNotFound'
import ScrollToTop from './ScrollToTop/ScrollToTop'

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
const Layout = () => {
  return (
    <>

      <Router>
        <Header />
        <ScrollToTop>

          <Routes>

            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Feature />} />
            <Route path="/modules" element={<Modules />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="*" element={<PageNotFound />} />



          </Routes>
        </ScrollToTop>

        <Footer />
      </Router>

    </>
  )
}

export default Layout;