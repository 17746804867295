import React from "react";
import './Footer.css'
import { NavLink } from "react-router-dom";
import blue from '../../../assets/images/blue-circle.png'
import pink from '../../../assets/images/pink-circle.png'
import grey from '../../../assets/images/grey-circle.png'
import yellow from '../../../assets/images/yellow-circle.png'
import playstore from '../../../assets/images/playStore (1).png'
import appstore from '../../../assets/images/playStore (2).png'
import Fade from 'react-reveal/Fade';
import Social from "../Social"

function Footer() {
  return (
    <section id="footer">


      <div className="bubble-images">
        <div class="image-one hero-moveable-images">
          <img src={blue} alt="" className="moveable-css" />
        </div>
        {/* <div class="image-two">
        <img src={pink} alt="" className="moveable-css" />
      </div> */}
        <div class="image-three">
          <img src={grey} alt="" className="moveable-css" />
        </div>
        <div class="image-four">
          <img src={yellow} alt="" className="moveable-css" />
        </div>
      </div>


      <footer class="py-5 text-start container">
        <Fade bottom>
          <div class="container">
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <h2 className="main-heading mb-3">
                  Get Prime HRMS Today
                </h2>
                <div className="subheading">
                  Sign up and start improving <br></br>your company's Human Resources management!




                </div>
                <div className="hero-buttons mt-4">
                <a href="https://web.primehrms.com/contact-us">
                  <button className="btn first">
                    Contact Us
                  </button>
                  </a>
                </div>
              </div>
              <div className="col-lg-4">
                <h2 className="main-heading mb-3">
                  Lets Get Social
                </h2>
                <Social />

              </div>

              <div className="col-lg-3">
              
                <h2 className="main-heading mb-3">
                  Get our App
                </h2>
                
                <div className="store-logos">
                <a href="https://play.google.com/store/apps/details?id=com.h3solutions.primehrms.ess">
                  <img src={playstore} alt="" />
                  </a>
                </div>
                <div className="store-logos1">
                <a href="https://apps.apple.com/pk/app/primehrms-ess/id1448029069">
                  <img src={appstore} alt="" />
                  </a>
                </div>

              </div>

            </div>
          </div>
        </Fade>
      </footer>

      <div className="footer-menus">
        <hr />
        <ul className="footer-menus-list">
          <li className="footer-menus-list">
            <NavLink className="nav-link" to="/">
              © 2022 Prime HRMS Rights Reserved.
            </NavLink>
          </li>

          {/* <li className="footer-menus-list">
                <NavLink className="nav-link" to="/features">
                  Features
                </NavLink>
              </li>
              <li className="footer-menus-list">
                <NavLink className="nav-link" to="/modules">
                  Modules
                </NavLink>
              </li>
              <li className="footer-menus-list">
                <NavLink className="nav-link" to="/pricing">
                  Pricing
                </NavLink>
              </li> */}


        </ul>
      </div>

    </section>

  );
}

export default Footer;