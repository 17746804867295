

import React from 'react'
import './Pricing.css'
import { GiPayMoney } from "react-icons/gi"
import { TbReportMoney } from "react-icons/tb"
import { HiUser } from "react-icons/hi"
import { BsFillCalendarDateFill } from "react-icons/bs"
import { BsGraphUp } from "react-icons/bs"
import { FaPersonBooth } from "react-icons/fa"
import { FaHandsHelping } from "react-icons/fa"
import { HiDocumentReport } from "react-icons/hi"
import { GiPlayerTime } from "react-icons/gi"
import { ImPriceTag } from "react-icons/im"
import { CgPerformance } from "react-icons/cg"
import Fade from 'react-reveal/Fade';

import yellow from '../../../../assets/images/yellow-circle.png'
import pink from '../../../../assets/images/pink-circle.png'

const Pricing = () => {


    return (
        <section id="pricing">
            <div className="bubble-images">
                <div class="image-pink hero-moveable-images">
                    <img src={pink} alt="" className="moveable-css" />
                </div>

                <div class="image-yellow hero-moveable-images">
                    <img src={yellow} alt="" className="moveable-css" />
                </div>
            </div>


            <div className="container">
                <Fade bottom cascade>
                    <div className="row price-heading text-center">

                        <div className="pricing-sub main-subheading">
                            <span className="sub-text">
                                Price for everyone
                            </span>
                        </div>
                        <h2 className="main-heading">
                            Affordable pricing for everyone
                        </h2>

                        <p className="pricing-desc mt-4">
                        Start with a base plan below, then choose your add-ons.

                        </p>
                    </div>
                </Fade>
                <div className="pricing-cards">
                    <div className="row" >
                    
                        <div className="col-lg-4">
                            <div className="pricng-card-top pricing-card-left">

                                <div className="card-top-sub main-subheading">
                                    <span>Basic</span>
                                </div>
                                <div className="pricing-card-price">
                                    <h2 className='main-heading'>
                                        $1.5
                                    </h2>
                                    <h6 className='per-monthprice'>/month  /employee</h6>
                                </div>
                                <p className="pricing-card-desc2">
                                    *Prices may vary acording to employee count.
                                </p>
                            </div>


                            <div className="pricing-card-list">
                                <ul className="pricing-list">
                                <li className="list-items"><BsGraphUp />Attendance Management </li>
                                    <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                    <li className="list-items"><HiUser />Employee Management </li>
                                    <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                    <li className="list-items"><GiPlayerTime />ESS</li>
                                    <li className="list-items">&nbsp;</li>
                                    <li className="list-items">&nbsp;</li>
                                    <li className="list-items">&nbsp;</li>
                                    <li className="list-items">&nbsp;</li>
                                    <li className="list-items">&nbsp;</li>
                                 
                                </ul>
                            </div>

                            <div className="pricing-button">
                                <button className="try-free btn">
                                <h6>Email Level Support</h6> 
                                    <h6>No Customization</h6>
                                
                                </button>
                            </div>


                     
                        </div>
                        <div className="col-lg-4 pricing-card-highlighted">

                            <div className="pricng-card-top ">
                                <div className="card-top-sub main-subheading">
                                    <span>Advanced</span>
                                </div>
                                <div className="pricing-card-price">
                                    <h2 className='main-heading'>
                                    $2
                                    </h2>
                                    <h6 className='per-monthprice'>/month /employee</h6>

                                </div>
                               
                                <p className="pricing-card-desc2">
                                    *Prices may vary acording to employee count.
                                </p>
                            </div>


                            <div className="pricing-card-list">
                                <ul className="pricing-list">
                                <li className="list-items"><BsGraphUp />Attendance Management </li>
                                    <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                    <li className="list-items"><HiUser />Employee Management </li>
                                    <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                    <li className="list-items"><TbReportMoney />Payroll</li>
                                    <li className="list-items"><GiPlayerTime />ESS</li>
                                    <li className="list-items"><GiPayMoney />Expense Management </li>
                                    <li className="list-items"><FaHandsHelping />Final Settlement</li>
                              

                                    
                                </ul>
                            </div>

                            <div className="pricing-button">
                                <button className="try-free btn">
                                <h6>Email & Skype Level Support</h6> 
                                    <h6>Minimal Customization</h6>
                               
                                </button>
                            </div>


                         

                        </div>
                        <div className="col-lg-4">

                            <div className="pricng-card-top pricing-card-left">
                                <div className="card-top-sub main-subheading">
                                    <span>Premium</span>
                                </div>
                                <div className="pricing-card-price">
                                    <h2 className='main-heading'>
                                        $3
                                    </h2>
                                    <h6 className='per-monthprice'>/month /employee</h6>

                                </div>
                                
                                <p className="pricing-card-desc2">
                                    *Prices may vary acording to employee count.
                                </p>
                            </div>


                            <div className="pricing-card-list">
                                <ul className="pricing-list">
                                <li className="list-items"><BsGraphUp />Attendance Management </li>
                                    <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                    <li className="list-items"><HiUser />Employee Management </li>
                                    <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                    <li className="list-items"><TbReportMoney />Payroll</li>
                                    <li className="list-items"><GiPlayerTime />ESS</li>
                                    <li className="list-items"><GiPayMoney />OPD Management </li>
                                    <li className="list-items"><TbReportMoney />Expense Management </li>
                                    <li className="list-items"><HiDocumentReport />Complaint Management</li>
                                    <li className="list-items"><CgPerformance />Performance Management System </li>
                                    <li className="list-items"><FaPersonBooth />Applicant Tracking System </li>
                                    <li className="list-items"><FaHandsHelping />Final Settlement</li>
                                </ul>
                            </div>

                            <div className="pricing-button">
                                <button className="try-free btn">
                                    <h6>Support via call or any medium</h6> 
                                    <h6> Customization & Integrations</h6>
                                </button>
                            </div>

                    

                        </div>
                       
                    </div>
                </div>
                <div className="faq-footer text-center">
                            <p>
                            </p>
                            <a href="https://web.primehrms.com/contact-us"><button type="button" class="btn first">Schedule a Demo</button></a>

                        </div>

            </div>
        </section>
    )
}

export default Pricing