


import React from 'react'
import './Fifth.css'
import fifth from '../../../../assets/images/fifth-img.png'
// import attached from '../../../../assets/images/attached.png'
import dashboard from '../../../../assets/images/a23.PNG'
import Sixth from '../../../../assets/images/Webinar.gif'
import video from '../../../../assets/images/movie2.mp4'
import { MdKeyboardArrowRight } from "react-icons/md"
import { FaPlay } from "react-icons/fa"
import Fade from 'react-reveal/Fade';


const Fifth = () => {
    return (
        <>

<section id="Sixth" class="pb-0">
                <div className="container">
                    <div className="row image-text-wrapper-second">

                        <div className="col-lg-6 image-text-content">
                        <Fade bottom cascade>
                            <div className="sticky-scroll">
                              {/*   <div className="icon-play mb-4">
                                    <FaPlay />
                                </div>
                                
                                <h2 className="main-heading">
                                    Prime HRMS empowers your Work Force to track and streamline their HR operations
                                </h2> */}
                                <div className="modules-sub main-subheading">
                            <span className="sub-text">
                                    Quick Overview
                            </span>
                        </div>
                                <h2 className="main-heading text-center">
                                    What We Offer
                                </h2>
                                <div class="feature-img text-center">
                                <img src={Sixth} className="w-100" alt="" />
                                </div>
                            </div>
                            </Fade>
                        </div>

                        <div className="col-lg-6 image-wrap ">
                            
                            {/* <img src={Sixth} alt="" className="Sixth w-100" /> */}
                           
                            {/* <video src={video}  controls ></video> */}
                            <iframe width="560" height="515" src="https://www.youtube.com/embed/EHnXeiemk1U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>

                </div>
            </section>
            <section id="fifth"  class="pt-0">
                <div className="container widthnew">
                    <div className="row image-text-wrapper-first">
                    <div className="col-lg-2">
                        </div>
                    <div className="col-lg-8">
                        <Fade bottom cascade>
                            <div className="sticky-scroll">
                                <h2 className="main-heading react-reveal main-heading">
                                Single Dashboard for All your Employee Needs
                                </h2>
                                <p> Uniquely network business experiences for resource sucking solutions. Dynamically re-engineer cooperative networks via cross-media expertise.</p>
                                {/* <p className="check_all">Check all features <MdKeyboardArrowRight /></p> */}
                            </div>
                            </Fade>
                        </div>
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-12 image-wrap">

                            <img src={dashboard} alt="" className="fifth w-100" />

                        </div>
                       
                    </div>

                </div>
            </section>

          

        </>



    )
}

export default Fifth