
import React from 'react';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Layout from './component/layout/Layout';


function App() {
  return(<Layout />)
}
export default App
