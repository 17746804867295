
import React from 'react'
import './PricingPage.css'
import Sixth from '../../../assets/images/sixth-img.png'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'
import { Container, Row, Col } from "reactstrap"
import { GiPayMoney } from "react-icons/gi"
import { TbReportMoney } from "react-icons/tb"
import { HiUser } from "react-icons/hi"
import { BsFillCalendarDateFill } from "react-icons/bs"
import { BsGraphUp } from "react-icons/bs"
import { FaPersonBooth } from "react-icons/fa"
import { FaHandsHelping } from "react-icons/fa"
import { HiDocumentReport } from "react-icons/hi"
import { GiPlayerTime } from "react-icons/gi"
import { CgPerformance } from "react-icons/cg"
import Fade from 'react-reveal/Fade';

const PricingPage = () => {
    var sectionStyle = {
        width: "100%",
        backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",


    };

    const FaqItem = [
        {
            question: "What payment methods do you accept?",
            answer: "Prime HRMS accepts electronic bank transfers using ACH, and all major credit and debit cards.",
        },
        {
            question: "When am I billed by Prime HRMS?",
            answer: "Prime HRMS bills annual contracts immediately/on the day of signing and monthly contracts on the anniversary of the signing date",
        },
        {
            question: "Can I create a custom bundle?",
            answer: "While you can't customize the services included with your Prime HRMS plan, you can add any of the available add-on services to your selected plan to create the best all-in-one HR solution for your organization.",
        },
        {
            question: "What does the minimum 5 employee requirement apply to?",
            answer: "Our HR Base Plans and Add-Ons have a minimum of 5 employees. You can have fewer employees using the Prime HRMS platform than this, but you will still be charged for the minimum 5 employees for each of the products and services you use.",
        },
    ]


    return (
        <div className="main-content">
            <div className="feature-gradient">
                <div className="feature-bg" style={sectionStyle}>
                    <div className="container">
                        <div className="row feature-bg-row">
                            <Fade left>
                                <div className="col-lg-6 feature-banner-col">
                                    <div className="feature-banner-content">
                                        <h2>
                                            Prime HRMS Pricing


                                        </h2>
                                        <p className="banner-desc">
                                            Start with a base plan below, then choose your add-ons.

                                        </p>
                                        <a href="https://primehrms.com/PrimeRegistration.aspx">
                                <button className="btn btn-light">
                                    Schedule a Demo
                                </button>
                                </a>
                                    </div>
                                </div>
                            </Fade>
                            <Fade right>


                                <div className="col-lg-6">
                                    <div className="feature-banner-imgs">
                                        <div className="banner-img">
                                            <img src={Sixth} alt="" className="hero-moveable-images" />
                                        </div>




                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <section id="pricingPage">
                <Container>
                    <Row className='pricingPage-section text-center'>
                        <div className="main-subheading">
                            Affordable prices
                        </div>
                        <h2 className="main-heading">
                            Affordable for any level <br></br> of business
                        </h2>
                        <p className="pricing-desc">
                            Start with a base plan below, then choose your add-ons.
                        </p>
                    </Row>

                    <section id="pricing-cards">
                        <div className="pricing-cards pricingPage-cards">
                            <div className="row">



                                <div className="col-lg-4 pricingPage-col  ">
                                    <div className="pricng-card-top pricing-card-left">

                                        <div className="card-top-sub main-subheading">
                                            <span>Basic</span>
                                        </div>
                                        <div className="pricing-card-price">
                                            <h2 className='main-heading'>
                                                $1.5
                                            </h2>
                                            <h6 className='per-monthprice'>/month  /employee</h6>

                                        </div>
                                        <p className="pricing-card-desc">
                                            Email Level Support <br></br> No Customization
                                        </p>
                                        <p className="pricing-card-desc2">
                                            *Prices may vary acording to employee count.
                                        </p>

                                    </div>


                                    <div className="pricing-card-list">
                                        <ul className="pricing-list">
                                            <li className="list-items"><BsGraphUp />Attendance Management </li>
                                            <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                            <li className="list-items"><HiUser />Employee Management </li>
                                            <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                            <li className="list-items"><GiPlayerTime />ESS</li>
                                            <li className="list-items">&nbsp;</li>
                                            <li className="list-items">&nbsp;</li>
                                            <li className="list-items">&nbsp;</li>
                                            <li className="list-items">&nbsp;</li>
                                            <li className="list-items">&nbsp;</li>

                                        </ul>
                                    </div>

                                    <div className="pricing-button">
                                        <a href="https://primehrms.com/PrimeRegistration.aspx">
                                            <button className="try-free btn">
                                                Free Sign Up
                                            </button>
                                        </a>
                                    </div>



                                </div>

                                <div className="col-lg-4 pricingPage-col pricing-card-highlighted  ">

                                    <div className="pricng-card-top ">
                                        <div className="card-top-sub main-subheading">
                                            <span>Advanced</span>
                                        </div>
                                        <div className="pricing-card-price">
                                            <h2 className='main-heading'>
                                                $2
                                            </h2>
                                            <h6 className='per-monthprice'>/month /employee</h6>

                                        </div>
                                        <p className="pricing-card-desc">
                                            Email & Skype Level Support <br></br> Minimal Customization
                                        </p>
                                        <p className="pricing-card-desc2">
                                            *Prices may vary acording to employee count.
                                        </p>

                                    </div>


                                    <div className="pricing-card-list">
                                        <ul className="pricing-list">
                                            <li className="list-items"><BsGraphUp />Attendance Management </li>
                                            <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                            <li className="list-items"><HiUser />Employee Management </li>
                                            <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                            <li className="list-items"><TbReportMoney />Payroll</li>
                                            <li className="list-items"><GiPlayerTime />ESS</li>
                                            <li className="list-items"><GiPayMoney />Expense Management </li>
                                            <li className="list-items"><FaPersonBooth />Complaint Management</li>
                                            <li className="list-items"><CgPerformance />Performance Management System </li>
                                            <li className="list-items"><FaHandsHelping />Final Settlement</li>



                                        </ul>
                                    </div>

                                    <div className="pricing-button">
                                        <a href="https://primehrms.com/PrimeRegistration.aspx">
                                            <button className="try-free btn">
                                                Free Sign Up
                                            </button>
                                        </a>
                                    </div>




                                </div>
                                <div className="col-lg-4 pricingPage-col  ">

                                    <div className="pricng-card-top pricing-card-left">
                                        <div className="card-top-sub main-subheading">
                                            <span>Premium</span>
                                        </div>
                                        <div className="pricing-card-price">
                                            <h2 className='main-heading'>
                                                $3
                                            </h2>
                                            <h6 className='per-monthprice'>/month /employee</h6>

                                        </div>
                                        <p className="pricing-card-desc">
                                            Support via call or any medium <br></br> integration &
                                            customization
                                        </p>
                                        <p className="pricing-card-desc2">
                                            *Prices may vary acording to employee count.
                                        </p>

                                    </div>


                                    <div className="pricing-card-list">
                                        <ul className="pricing-list">
                                            <li className="list-items"><BsGraphUp />Attendance Management </li>
                                            <li className="list-items"><BsFillCalendarDateFill />Leave Management</li>
                                            <li className="list-items"><HiUser />Employee Management </li>
                                            <li className="list-items"><HiDocumentReport />Reports & Analysis</li>
                                            <li className="list-items"><TbReportMoney />Payroll</li>
                                            <li className="list-items"><GiPlayerTime />ESS</li>
                                            <li className="list-items"><GiPayMoney />Expense Management </li>
                                            <li className="list-items"><FaPersonBooth />Complaint Management</li>
                                            <li className="list-items"><CgPerformance />Performance Management System </li>
                                            <li className="list-items"><FaHandsHelping />Final Settlement</li>
                                        </ul>
                                    </div>


                                    <div className="pricing-button">
                                        <a href="https://primehrms.com/PrimeRegistration.aspx">
                                            <button className="try-free btn">
                                                Free Sign Up
                                            </button>
                                        </a>
                                    </div>




                                </div>

                            </div>
                        </div>
                        <div className="faq-footer text-center">
                            <p>This price is for employees upto 100, for more than 100 employees, please contact
                            </p>
                            <a href="https://web.primehrms.com/contact-us"><button type="button" class="btn first">Schedule a Demo</button></a>

                        </div>
                        {/* <div className="pricing-employee-cards">
                            <div className="row justify-content-center ">
                                <div className="col-lg-4 pricingPage-col  ">
                                    <div className="pricing-card-list1">
                                        <h2 className="main-subheading">
                                            Basic
                                        </h2>
                                        <div className="row box-shadow-employe" >
                                            <div className="col-lg-5">
                                                <span className="pricing-list-employee-heading">
                                                    Employees Number
                                                </span>

                                                <ul className="pricing-list-employee">

                                                    <li className="list-items">Employees 1-20 </li>
                                                    <li className="list-items">Employees 21-100</li>
                                                    <li className="list-items">Employees 101 - 200</li>
                                                    <li className="list-items">Employees 200 Above</li>


                                                </ul>
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="pricing-list-employee-heading">
                                                    Subscription charges
                                                </span>
                                                <ul className="pricing-list-employee">
                                                    <li className="list-items">25,000 </li>
                                                    <li className="list-items">40,000</li>
                                                    <li className="list-items">75,000</li>
                                                    <li className="list-items">90,000</li>


                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <span className="pricing-list-employee-heading">
                                                    /Employee Rate
                                                </span>

                                                <ul className="pricing-list-employee">


                                                    <li className="list-items">4000/month </li>
                                                    <li className="list-items">250</li>
                                                    <li className="list-items">225</li>
                                                    <li className="list-items">200</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 pricingPage-col   pricing-card-highlighted">
                                    <div className="pricing-card-list2">
                                        <h2 className="main-subheading">
                                            Advanced
                                        </h2>
                                        <div className="row box-shadow-employe">
                                            <div className="col-lg-5">
                                                <span className="pricing-list-employee-heading">
                                                    Employees Number
                                                </span>

                                                <ul className="pricing-list-employee">

                                                    <li className="list-items">Employees 1-20 </li>
                                                    <li className="list-items">Employees 21-100</li>
                                                    <li className="list-items">Employees 101 - 200</li>
                                                    <li className="list-items">Employees 200 Above</li>


                                                </ul>
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="pricing-list-employee-heading">
                                                    Subscription charges
                                                </span>
                                                <ul className="pricing-list-employee">
                                                    <li className="list-items">35,000 </li>
                                                    <li className="list-items">60,000</li>
                                                    <li className="list-items">100,000</li>
                                                    <li className="list-items">150,000</li>


                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <span className="pricing-list-employee-heading">
                                                    /Employee Rate
                                                </span>

                                                <ul className="pricing-list-employee">


                                                    <li className="list-items">5000/month </li>
                                                    <li className="list-items">300</li>
                                                    <li className="list-items">270</li>
                                                    <li className="list-items">250</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 pricingPage-col  ">
                                    <div className="pricing-card-list1">
                                        <h2 className="main-subheading">
                                            Premium
                                        </h2>
                                        <div className="row box-shadow-employe">
                                            <div className="col-lg-5">
                                                <span className="pricing-list-employee-heading">
                                                    Employees Number
                                                </span>

                                                <ul className="pricing-list-employee">

                                                    <li className="list-items">Employees 1-20 </li>
                                                    <li className="list-items">Employees 21-100</li>
                                                    <li className="list-items">Employees 101 - 200</li>
                                                    <li className="list-items">Employees 200 Above</li>


                                                </ul>
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="pricing-list-employee-heading">
                                                    Subscription charges
                                                </span>
                                                <ul className="pricing-list-employee">
                                                    <li className="list-items">50,000 </li>
                                                    <li className="list-items">75,000</li>
                                                    <li className="list-items">120,000</li>
                                                    <li className="list-items">175,000</li>


                                                </ul>
                                            </div>

                                            <div className="col-lg-3">
                                                <span className="pricing-list-employee-heading">
                                                    /Employee Rate
                                                </span>

                                                <ul className="pricing-list-employee">


                                                    <li className="list-items">7000/month </li>
                                                    <li className="list-items">400</li>
                                                    <li className="list-items">370</li>
                                                    <li className="list-items">350</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                    </section>





                </Container>







            </section>



        </div>
    )
}

export default PricingPage