import React from "react";
import './Privacy.css'
import Sixth from '../../../assets/images/sixth-img.png'
import Sixthe from '../../../assets/images/Security.gif'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'

import Fade from 'react-reveal/Fade';
import { useEffect } from "react";
import { Container, Row} from "reactstrap"


function Privacy() {


  useEffect(() => {
    document.body.classList.add("privacy-page");
    return () => {
      document.body.classList.remove("privacy-page");
    };
  }, []);

  var sectionStyle = {
    width: "100%",
    backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",


  };

  return (
    <div className="main-content">
      <div className="feature-gradient">
        <div className="feature-bg" style={sectionStyle}>
          <div className="container">
            <div className="row feature-bg-row">
            <Fade left>
              <div className="col-lg-6 feature-banner-col">
                <div className="feature-banner-content">
                  <h2>
                   Privacy policy

                  </h2>
                  <p className="banner-desc">
                    Create stunning features with unlimited style possibilities.
                  </p>
                  <a href="https://primehrms.com/PrimeRegistration.aspx">
                    <button className="btn btn-light">Schedule a Demo</button>
                    </a>
                </div>
              </div>
              </Fade>
              <Fade right>
              <div className="col-lg-6">
                <div className="feature-banner-imgs">
                 <div className="banner-img">
                    <img src={Sixth} alt="" />
                 </div>



                </div>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <section id="footer-info-section">
      <div className="container">
                <div className="row featured-content-row">
                <Fade bottom cascade>
                    <div className="col-lg-12">
                    <h2 className="main-heading mb-4 ">
                        Your Assurance Our Responsibility

                        </h2>
                    </div>
                    </Fade>
                    </div>
                    </div>
        <Container>
          <Row>
          <div className="col-lg-6">
           
            <h6>Prime HRMS Software Privacy Policy</h6>
            <p className="f-15">
            This Privacy Policy governs the manner in which Prime HRMS collects, uses, maintains and discloses information collected from users (each, a "User") of the http://web.primehrms.com website ("Site"). This privacy policy applies to the Site and all products and services offered by web.primehrms.com.
            </p>

            <h6>Personal identification information</h6>
            <p className="f-15">
            We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, register on the site, and in connection with other activities, services, features or resources we make available on our site:: Users may be asked for, as appropriate, name, email address, mailing address, phone number. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
            </p>

            <h6>Non-personal identification information</h6>
            <p className="f-15">
            We may collect non-personal identification information about Users whenever they interact with our site: Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
            </p>
            </div>
            <div className="col-lg-6">
            <div className="feature-img">
            <img src={Sixthe} alt="" />
            </div>
            </div>
            </Row>
            <Row>
            <div className="col-lg-12">

            <h6>Web browser cookies</h6>
            <p className="f-15">
            Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
            </p>

            <h6>How we use collected information</h6>
            <p className="f-15">
            Prime HRMS may collect and use Users personal information for the following purposes:
            </p>
            <span className="f-15">- To improve customer service</span>
            <span className="f-15">Information you provide helps us respond to your customer service requests and support needs more efficiently.</span>
            <span className="f-15">- To personalize user experience</span>
            <span className="f-15">- To improve our Site</span>
            <span className="f-15">We may use feedback you provide to improve our products and services.</span>
            <span className="f-15">- To process payments</span>
            <span className="f-15">We may use the information Users provide about themselves when signing up. We do not share this information with outside parties except to the extent necessary to provide the service.</span>
            <span className="f-15">- To send periodic emails</span>
            <p className="f-15">We may use the email address to send User information and updates pertaining to their services. It may also be used to respond to their inquiries, questions, and/or other requests.</p>
            

            <h6>How we protect your information</h6>
            <p className="f-15">
            We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your personal information, username, password, transaction information and data stored on our site:
            </p>
            <p className="f-15">
            Sensitive and private data exchange between the Site and its Users happens over a SSL secured communication channel and is encrypted and protected with digital signatures.
            </p>

            <h6>Changes to this privacy policy</h6>
            <p className="f-15">
            Prime HRMS has the discretion to update this privacy policy at any time. When we do, we will post a notification on the main page of our Site, revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
            </p>

            <h6>Your acceptance of these terms</h6>
            <p className="f-15">
            By using this Site and our software services, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our site: Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
            </p>

            <h6>Contacting us</h6>
            <p className="f-15">
            If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:
            </p>
            <p className="f-15">info@primehrms.com</p>

            </div>
           
          </Row>
        </Container>
      </section>

    </div>
  );
}

export default Privacy;