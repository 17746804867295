

import React from 'react'
import './Features.css'
import BiTimeFive  from '../../../../assets/images/fingerprint.gif'
import CgPerformance from '../../../../assets/images/performance.gif' 
import BsFillPersonCheckFill from '../../../../assets/images/ats.gif' 
import GiOfficeChair from '../../../../assets/images/onoff.gif' 
import BsGraphUp from '../../../../assets/images/employee.gif' 
import MdAttachMoney  from '../../../../assets/images/payroll.gif' 
import BsGraphdown from '../../../../assets/images/leaves.gif' 
import MdAttachOver  from '../../../../assets/images/overtime.gif' 
import ess  from '../../../../assets/images/ess.gif' 
import loan  from '../../../../assets/images/loan.gif'
import reports  from '../../../../assets/images/reports.gif' 
import task  from '../../../../assets/images/task.gif'

import Fade from 'react-reveal/Fade';
const Features = () => {

    const FeatureItems = [
        {
            imgIcon: BiTimeFive ,
            title: "Time and Attendance",
            desc: "Prime HRMS is sophisticated platform for attendance management. Not only is it responsible for maintaining visibility on employee movements",
            classIcon: "icon-cirle one-icon-circle",
        },
        {
            imgIcon: CgPerformance ,
            title: "Performance Management            ",
            desc: "Prime HRMS provide a approach to measure the performance of employees. Through this your organization can align their objectives with resources systems",
            classIcon: "icon-cirle second-icon-circle",
        },
        {
            imgIcon: BsFillPersonCheckFill,
            title: "Applicant Tracking ",
            desc: "Prime HRMS provide an applicant tracking system (ATS) it enables the electronic handling of recruitment and hiring needs.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: GiOfficeChair,
            title: "On / Off boarding            ",
            desc: "Prime HRMS give simple and efficient on boarding system as HR Managers face numerous challenges on a day-to-day basis.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: BsGraphUp ,
            title: "Employee Management            ",
            desc: "We provide a platform where all work-related as well as important personal details of an employee is stored and managed in a secure way",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: MdAttachMoney,
            title: "Payroll",
            desc: "Prime HRMS provide a tool that enables your business to handle all your employee's financial records in a hassle-free, automated fashion",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: MdAttachOver,
            title: "Over Time",
            desc: "It helps you to define and configure overtime settings like minimum & maximum overtime hours, calculation factor for weekly.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: ess,
            title: "Employee Self Service            ",
            desc: "It provides management support system that facilitates and supports employees on daily basis.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: BsGraphdown,
            title: "Leave Management",
            desc: "It is used by every organizations to simplify, drop the administrative hurdles, monitor, plan, and track leaves of absences. It allows staff to request any leave of absence.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: loan,
            title: "Loans & Advances",
            desc: "Prime HRMS help automate the entire loan lifecycle. Depending on requirements. Prime HRMS can help with processing customer information more",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: task,
            title: "Task Management",
            desc: "Task management module of Prime HRMS deals with various aspects of a project such as dividing the whole project into smaller tasks and subtasks.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: reports,
            title: "Reports & Analysis",
            desc: "Prime HRMS provide feature collecting and analyzing Human Resource (HR) data in order to improve an organization’s workforce performance.",
            classIcon: "icon-cirle third-icon-circle",
        }

    ]


    return (
        <section id="featured">
            <div className="container">
                <div className="row featured-content-row">
                <Fade bottom cascade>
                    <div className="col-lg-12">
                    <div className="modules-sub main-subheading">
                            <span className="sub-text">
                               Powerful Solutions
                            </span>
                        </div>
                        <h2 className="main-heading mb-4  text-center">
                        Experience your HR with our Solutions

                        </h2>
                       
                        
                    </div>
                    </Fade>
                    <div className="col-lg-12">
                        <div className="row">

                            <div className="features-card">
                            <div className="feature-content">
                                <div className="row g-7">

                                    {FeatureItems.map((item, index) => (
                                        <div className="col-lg-5">
                                            
                                                <div className="row">
                                                <div className="feature-items">
                                                    <div className="col-3">
                                                        <div className="feature-img">
                                                            {/* <div className={item.classIcon}>{item.imgIcon}</div> */}
                                                            <img src={item.imgIcon} className="w-100" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-9">
                                                        <div className="feature-data">


                                                            <div className="feature-title"><h2 className='feature-title-h2'>{item.title}</h2></div>
                                                            <div className="feature-desc f-15">{item.desc}</div> 
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    )
                                    )}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features