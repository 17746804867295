import React from 'react'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'
import './PageNotFound.css'


const PageNotFound = () => {
    var sectionStyle = {
        width: "100%",
        backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    
    
      };

      
  return (
    <div className='main-content'>
          <div className="feature-gradient page-notfound">
        <div className="feature-bg" style={sectionStyle}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 feature-banner-col text-center">
                <div className="feature-banner-content">
                  <h2>
                    404 Page not found

                  </h2>
                </div>
              </div>
       
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound