import React from "react";
import './FeaturePage.css'
import Sixth from '../../../assets/images/sixth-img.png'
import FeatureBg from '../../../assets/images/FeatureBg.jpg'
import sidebarfeature from '../../../assets/images/sidebarfeature.png'
import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap"
import { BiTimeFive } from "react-icons/bi"
import { CgPerformance } from "react-icons/cg"
import { BsFillPersonCheckFill } from "react-icons/bs"
import { GiOfficeChair } from "react-icons/gi"
import { BsGraphUp } from "react-icons/bs"
import { MdAttachMoney } from "react-icons/md"
import Fade from 'react-reveal/Fade';


function Feature() {

  const featurepagelist = [
    {
      imgIcon: <BiTimeFive />,
      title: "Time and Attendance",
      desc: " Take control of your employees’ absenteeism and increase efficiency.",
      classIcon: "icon-cirle one-icon-circle",
    },
    {
      imgIcon: <CgPerformance />,
      title: "Performance Management            ",
      desc: "Set goals and track the progress made by the employees.",
      classIcon: "icon-cirle second-icon-circle",
    },
    {
      imgIcon: <BsFillPersonCheckFill />,
      title: "Applicant Tracking ",
      desc: "ind the right talent for open positions in your organization.",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: <GiOfficeChair />,
      title: "Onboarding            ",
      desc: "Make onboarding a better experience for your new employees.",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: <BsGraphUp />,
      title: "Employee Tracking            ",
      desc: "Employee activities and effectively track employee behavior. ",
      classIcon: "icon-cirle third-icon-circle",
    },
    {
      imgIcon: <MdAttachMoney />,
      title: "Payroll",
      desc: "Provide payroll system through you can manage your monthly payroll.",
      classIcon: "icon-cirle third-icon-circle",
    }

  ]


  useEffect(() => {
    document.body.classList.add("feature-page");
    return () => {
      document.body.classList.remove("feature-page");
    };
  }, []);

  var sectionStyle = {
    width: "100%",
    backgroundImage: `linear-gradient(to right, #1d439c, rgb(247 148 29 / 53%), rgb(31 162 255)), url(${FeatureBg})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",


  };

  return (
    <div className="main-content">
      <div className="feature-gradient">
        <div className="feature-bg" style={sectionStyle}>
          <div className="container">
            <div className="row feature-bg-row">
            <Fade left>
              <div className="col-lg-6 feature-banner-col">
                <div className="feature-banner-content">
                  <h2>
                    Features of PRIME HRMS

                  </h2>
                  <p className="banner-desc">
                    Create stunning features with unlimited style possibilities.
                  </p>
                  <a href="https://primehrms.com/PrimeRegistration.aspx">
                                <button className="btn first">
                                    Schedule a Demo
                                </button>
                                </a>
                </div>
              </div>
              </Fade>
              <Fade right>
              <div className="col-lg-6">
                <div className="feature-banner-imgs">
                 <div className="banner-img">
                    <img src={Sixth} alt="" />
                 </div>



                </div>
              </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>



      <section id="features-list">


        <Container>


          <Row>


            <div className="col-lg-8">
              <div className="features-list-items">
                <div className="main-subheading">
                  Make you efficient
                </div>
                <h2 className="main-heading text-center">
                  Check below the most<br>
                  </br> Advanced features
                </h2>

                <Row className="feature-list-row mt-5">


                  {featurepagelist.map((item, index) => (
                    <div className="col-lg-6">

                      <div className="row">
                        <div className="featurePage-items mb-5">
                          <div className="col-lg-3">
                            <div className="feature-img">
                              {item.imgIcon}
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="feature-data">


                              <div className="featurePage-title"><h2 className='featurePage-title-h2'>{item.title}</h2></div>
                              <div className="feature-desc">{item.desc}</div>
                            </div>
                          </div>

                        </div>
                      </div>


                    </div>

                  )
                  )}
                </Row>

              </div>
            </div>

            <div className="col-lg-4">
              <div className="features-sidebar-img">
                <img src={sidebarfeature} alt="" className="w-100" />
              </div>
              <div className="feature-sidebar-list shadow-lg">
                <ul className="feature-sidebar-list">

                  {featurepagelist.map((item, index) => (


                    <div className="featurePage-items mb-3">
                      <li key={index}>
                        <div className="sidebarfeature-img">{item.imgIcon}</div><div className="sidebar-feature-content"> {item.title}</div>
                      </li>
                    </div>


                  )
                  )}
                </ul>
              </div>
            </div>
          </Row>


        </Container>

      </section>


    </div>
  );
}

export default Feature;