

import React from 'react'
import './Third.css'
import leptop from '../../../../assets/images/FAQ.gif'
import { GiCheckMark } from "react-icons/gi"
import { BsPersonFill } from "react-icons/bs"
import { SiAnalogue } from "react-icons/si"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { TbDiamond } from "react-icons/tb"
import Slide from 'react-reveal/Slide';

const Third = () => {


    const FeaturesItem = [
        {
            imgIcon: <BsPersonFill />,
            title: "Will the software be able to handle the growth of my company?",
            desc: "Yes, Prime HRMS have everything you need to properly manage your HR program, it’s important to think of how your needs will shift in the long term. If you were to double the size of your workforce, Prime HRMS will grow as your company grows.",
        },
        {
            imgIcon: <GiCheckMark />,
            title: "Is the system cloud based?",
            desc: "Yes Prime HRMS is cloud based. If you’d like the software to reside on your servers, behind your own firewall, tended by your own IT staff, you’re more than likely going to lean toward the traditional licensed software model. This means you pay one lump sum for the system, plus an annual maintenance fee for support and updates.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: <SiAnalogue />,
            title: "Is it safe to put my financial data in the cloud?",
            desc: "In general, yes - but you should always make sure your HR software provider is well-established and trustworthy, with a good track record Reputable cloud software providers invest a lot of time and money in security, because their reputations depend on keeping customer data safe.Make sure your provider uses data encryption (usually called 'SSL encryption'), and search online to make sure they're well established and haven't suffered any major security breaches.",
        },
        {
            imgIcon: <GiCheckMark />,
            title: "What are the advantages of cloud-based HR ?",
            desc: "For many companies, moving their IT infrastructure and software to the cloud reduces the need for in-house support structure and saves the cost of server hardware. Cloud-based can be easier to scale functionality and users either up or down as required. And, it can often make it easier to share information across regions or locations. . We’re one of the few software providers that offers flexibility for our customers and still provides on-premise HR solutions. We help you choose based on your unique organizational requirements.",
            classIcon: "icon-cirle third-icon-circle",
        },
        {
            imgIcon: <SiAnalogue />,
            title: "Who Can See My Data in Prime HRMS?",
            desc: "The HR Software can be managed by giving individual access rights. Theoretically, every single data field can be managed separately. Every employee has the possibility to overview his own personnel data and to make changes through self-service functions.",
        }

    ]


    return (
        <section id="third-section">
            <div className="container">
                <div className="row features-content-wrap">
                    <div className="col-lg-6 ">
                        <div className="sticky-scroll">


                            <div className="features-img w-100">
                                <img className='w-100' src={leptop} alt="" />
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6">
                    <Slide bottom cascade>
                        <div className="feature_content">
                        <div className="client-goals-sub main-subheading">
              <span className="sub-text">
                FAQ
              </span>
            </div>
                                <h2 className="main-heading text-center">
                                
                                Knowledge Base
                                </h2>
                                <div className="feature_button-wrap">
                                    {/* <button className='btn feature-button'><AiOutlinePlusCircle /> More feature</button> 
                                    <button className='knowledge_base'> <TbDiamond /> Knowledge Base</button> */}
                                </div>

                                <div className="features-content-cards">

                                    {FeaturesItem.map((item, index) => (

                                        <div className="features-content">
                                            <div className='features-icon'>{item.imgIcon}</div>
                                            <div className="features-data-wrap">
                                                <div className="features-title"><h2 className='service-title-h2'>{item.title}</h2></div>
                                                <div className="features-desc">{item.desc}</div>
                                            </div>

                                        </div>



                                    )
                                    )}
                                    {/* <div className="feature-btn">
                                        <button type="button" class="btn btn-secondary">More features</button>
                                    </div> */}
                                </div>
                           
                        </div>
                        </Slide>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Third