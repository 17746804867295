

import React from 'react'
import './Sixth.css'
import star from '../../../../assets/images/star.png'
import iso from '../../../../assets/images/iso.gif'
import testimonial1 from '../../../../assets/images/gmsaT.png'
import testimonial2 from '../../../../assets/images/liverT.png'
import testimonial3 from '../../../../assets/images/sunriseT.png'

import testimonial4 from '../../../../assets/images/arhamT.png'
import testimonial5 from '../../../../assets/images/transT.png'
import testimonial6 from '../../../../assets/images/vertexT.png'
import testimonial7 from '../../../../assets/images/oziT.png'
import testimonial8 from '../../../../assets/images/nationalT.jpeg'
import testimonial9 from '../../../../assets/images/fieldT.png'
import testimonial10 from '../../../../assets/images/amentisT.png'
import testimonial11 from '../../../../assets/images/brilT.png'
import testimonial12 from '../../../../assets/images/aitT.png'

import Fade from 'react-reveal/Fade';
import Slider from 'react-slick'

const Sixth = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        swipeToSlide: true

    };

    const HeroBanner = [
        {
           
            title1: "Muhammad Asim           ",
            desc1: "GMSA",
            innerdesc1: "Prime HRMS has completely transformed the way we organize our HR functions. Our staff find it really easy to use and as a client I love the fact that everything I need is in the one place",
            testimoniala:testimonial1,

            title2: "Ashfaq Ahmad     ",
            desc2: "Liver Foundation",
            innerdesc2: "Being a user of prime hrms I honestly rely on it. we establish profitable long-term relationships with existing customers by not just satisfying but also delighting at each service encounter. Thanks so much for your support throughout the past few years",
            testimonialb:testimonial2,

            title3: "Ammar Iftikhar           ",
            desc3: "Sunrise Food Indstries",
            innerdesc3: " For us, there is absolutely no way to keep track of the amount of work coming in and deliverables going out without Prime HRMS.I would describe HRMS in these words 'Flexible product with near endless possibilities'",
            testimonialc:testimonial3,
        },
        {

            title1: "Mahmood Sultan           ",
            desc1: "Arhamsoft",
            innerdesc1: "Prime hrms was easy for us to use from the very start. Within two weeks we manage our HR things in more efficient manner. Because Prime HRMS is easy to use and intuitive we are able to manage our complicated HR things in easy way",
            testimoniala:testimonial4,

            title2: "Rabeet Malik          ",
            desc2: "Transdata",
            innerdesc2: "Prime HRMS's flexibility & customization are the most important part for our organization. The team is close to 20% more efficient overall by relying on Prime HRMS to keep their work organized and streamlined.",
            testimonialb:testimonial5,

            title3: "Khawaja Ali          ",
            desc3: "Vertex",
            innerdesc3: "Softwares like prime HRMS enable us to execute the kind of one-t- one service. This software is helping us to take our business to next level. Prime HRMS has been a part of my organization from years now",
            testimonialc:testimonial6,
        },
        {

            title1: "Muhammad Ejaz          ",
            desc1: "OZI Group",
            innerdesc1: "Prime HRMS improve the efficiency of my HR team. lt improve my employee experience. I also appreciate the support and training received from the Prime HRMS team. I wouldn't be without it!",
            testimoniala:testimonial7,


            title2: "Mr. Zulfiqar          ",
            desc2: "National Hospital",
            innerdesc2: "With Prime HRMS, we’re able to be strategic and proactive, rather than reactive, which has been critical. It boost your team’s alignment, efficiency, and productivity by customizing any workflow to fit your HR needs. ",
            testimonialb:testimonial8,

            title3: "Miss. Adila Khalid          ",
            desc3: "FieldForce",
            innerdesc3: "Being a user of prime hrms I honestly rely on it. we establish profitable long-term relationships with existing customers by not just satisfying but also delighting at each service encounter. Thanks so much for your support throughout the past few years.",
            testimonialc:testimonial9,
        },
        {

            title1: "Mr. Aadil          ",
            desc1: "Amentis",
            innerdesc1: "Prime HRMS has given me the confidence because when I walk into the room I know that when upper management asks for a certain piece of information I’m able to quickly provide it for them. It works as a backbone for me because I know I have a overview I can trust.",
            testimoniala:testimonial10,

            title2: "Waqas Ahmad          ",
            desc2: "Brilliant Gamerz",
            innerdesc2: "I like how prime HRMS I easy to use. I like their customer support and the way they customize things is highly appreciated because customization of things is not very common thing and I am highly impressed",
            testimonialb:testimonial11,
            
            title3: "Ahsan Ikram          ",
            desc3: "AIT",
            innerdesc3: "Prime HRMS has been an absolute lifesaver for working across teams and times zones, and keeping all of our HR organized. With Prime HRMS, we save an estimated 50 hours per week that used to be spent managing our employees & their payrolls. ",
            testimonialc:testimonial12,
        },
    ]
    return (
        <section id="sixth" className='screenshot_section'>
            
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                        <Fade bottom cascade>
                            <div className="row screenshot-wrapper">
                                
                                    <div className="client-goals-sub main-subheading">
                                        <span className="sub-text">
                                        Security Standards
                                        </span>
                                    </div>
                                    <h2 className="main-heading">
                                     Security Standards & Certificates
                                    </h2>
                            
                            </div>
                        </Fade>
                        <ul class="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                        <li class="py-1"><span class="f-15">SSL 2048</span></li>
                        <li class="py-1"><span class="f-15">DDOS Attack Protection</span></li>
                        <li class="py-1"><span class="f-15">ISO 27001</span></li>
                        <li class="py-1"><span class="f-15">ISO 27018</span></li>
                        <li class="py-1"><span class="f-15">ISO 9001</span></li>
                        <li class="py-1"><span class="f-15">ISO 22301</span></li>
                        <li class="py-1"><span class="f-15">ISO 31000</span></li>
                        <li class="py-1"><span class="f-15">SOC 1, SOC 2 AND SOC 3</span></li>
                        <li class="py-1"><span class="f-15">PCI</span></li>
                        <li class="py-1"><span class="f-15">HITRUST</span></li>
                        <li class="py-1"><span class="f-15">HIPAA</span></li>
                        <li class="py-1"><span class="f-15">EU-US PRIVACY SHIELD</span></li>
                    </ul>
                        </div>
                        <div className="col-lg-6">
                            <div class="feature-img">
                                <img className='w-100' src={iso} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div  id="seventhhh">
                <div className="container" >
                    <div className="row">
                        <div className="col-lg-12">
                        <Fade bottom cascade>
                            <div className="row screenshot-wrapper">
                                
                                    <div className="client-goals-sub main-subheading">
                                        <span className="sub-text">
                                        Testimonial
                                        </span>
                                    </div>
                                    <h2 className="main-heading">
                                    What They Say About Us
                                    </h2>
                            <p class="secreenshot-desc">
                            Uniquely promote adaptive quality vectors rather than stand-alone e-markets. pontificate alternative architectures whereas iterate.
                            </p>
                            </div>
                        </Fade>
                        <Slider {...settings}>
                                {
                                    HeroBanner.map((item, index) => (
                                            <div className="single_banner" key={index}>
                                                <div className='row'>
                                                    <div className="first-hero col-lg-4">
                                                        <div className="testimonial-hero">
                                                        <div className="testimonial-img">
                                                                <img src={item.testimoniala} className="w-100" alt="" />
                                                                </div>
                                                            <div className="feature-title">
                                                                <h2 className='feature-title-h2 mb-0'>{item.title1}</h2>
                                                            </div>
                                                            <small className="feature-desc f-15">{item.desc1}</small>
                                                            <br></br>
                                                            <blockquote>
                                                                <h6>{item.innertitle1}</h6>
                                                                <p className="feature-desc f-15">{item.innerdesc1}</p>
                                                            </blockquote>
                                                            <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                        <div className="second-hero  col-lg-4">
                                                        <div className="testimonial-hero">
                                                        <div className="testimonial-img">
                                                                <img src={item.testimonialb} className="w-100" alt="" />
                                                                </div>
                                                            <div className="feature-title">
                                                                <h2 className='feature-title-h2 mb-0'>{item.title2}</h2>
                                                            </div>
                                                            <small className="feature-desc f-15">{item.desc2}</small>
                                                            <br></br>
                                                            <blockquote>
                                                                <h6>{item.innertitle2}</h6>
                                                                <p className="feature-desc f-15">{item.innerdesc2}</p>
                                                            </blockquote>
                                                            <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                        <div className="second-hero  col-lg-4">
                                                        <div className="testimonial-hero">
                                                        <div className="testimonial-img">
                                                                <img src={item.testimonialc} className="w-100" alt="" />
                                                                </div>
                                                            <div className="feature-title">
                                                                <h2 className='feature-title-h2 mb-0'>{item.title3}</h2>
                                                            </div>
                                                            <small className="feature-desc f-15">{item.desc3}</small>
                                                            <br></br>
                                                            <blockquote>
                                                                <h6>{item.innertitle3}</h6>
                                                                <p className="feature-desc f-15">{item.innerdesc3}</p>
                                                            </blockquote>
                                                            <ul class="review-rate mb-0 mt-2 list-unstyled list-inline">
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                                <li class="list-inline-item"><img className='w-100' src={star} alt="" /></li>
                                                            </ul>
                                                        </div>
                                                        </div>
                                                </div>
                                            </div>
                                    ))
                                }
                            </Slider>
                        </div>
                        </div>
                        </div>
                        </div>
        </section>
    )
}

export default Sixth